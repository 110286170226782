import React from 'react';
import { Link } from 'gatsby';

import { container, pageLink, active, arrow, dots } from './pagination.module.scss';
import ArrowLeft from '../../assets/images/svg/arrow-left.svg';
import ArrowRight from '../../assets/images/svg/arrow-right.svg';

export interface IPaginationProps<PageLinkType extends React.ElementType = typeof Link> {
    className?: string;
    paginationPaths: string[];
    linkClassName?: string;
    edgeLinksClassName?: string;
    range?: number;
    PageLinkComponent?: PageLinkType;
}

const Pagination: React.FC<IPaginationProps> = ({
    className = '',
    paginationPaths,
    linkClassName = '',
    edgeLinksClassName = '',
    range = 2,
    PageLinkComponent = Link,
}) => {
    const pagePath = getPagePath() || '';
    const activeIndex = paginationPaths.indexOf(pagePath);
    const minIndex = activeIndex - range > 0 ? activeIndex - range : 0;
    const maxIndex =
        paginationPaths.length < activeIndex + range
            ? paginationPaths.length
            : activeIndex + range + 1;

    const keysRange = paginationPaths.slice(minIndex, maxIndex);

    if (paginationPaths.length <= 1) {
        return null;
    }

    return (
        <div aria-label="pagination navigation" className={`${container} ${className}`}>
            {activeIndex > 0 && (
                <PageLinkComponent
                    to={paginationPaths[activeIndex - 1]}
                    className={`${pageLink} ${arrow} ${edgeLinksClassName}`}
                >
                    <ArrowLeft />
                </PageLinkComponent>
            )}

            {minIndex > 0 && (
                <PageLinkComponent
                    to={paginationPaths[activeIndex / 2]}
                    className={`${pageLink} ${linkClassName}`}
                >
                    <span className={dots}>...</span>
                </PageLinkComponent>
            )}

            {keysRange.map((key, index) => {
                const isActive = paginationPaths.indexOf(key) === activeIndex;
                return (
                    <PageLinkComponent
                        key={`pagination-link-${index}`}
                        to={key}
                        className={`${pageLink} ${linkClassName} ${isActive ? active : ''}`}
                    >
                        {paginationPaths.indexOf(key) + 1}
                    </PageLinkComponent>
                );
            })}

            {maxIndex < paginationPaths.length && (
                <PageLinkComponent
                    to={paginationPaths[(activeIndex + paginationPaths.length - 1) / 2]}
                    className={`${pageLink} ${linkClassName}`}
                >
                    <span className={dots}>...</span>
                </PageLinkComponent>
            )}

            {activeIndex < paginationPaths.length - 1 && (
                <PageLinkComponent
                    to={paginationPaths[activeIndex + 1]}
                    className={`${pageLink} ${arrow} ${edgeLinksClassName}`}
                >
                    <ArrowRight />
                </PageLinkComponent>
            )}
        </div>
    );
};

function getPagePath() {
    if (typeof window === 'undefined') return;
    return `${window.location.pathname}${window.location.search}`;
}

export default Pagination;
