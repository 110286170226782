import React, { useState } from 'react';

import { button, overwrite, active, insideWrapper } from './upwards-button.module.scss';
import ArrowUpIcon from '../../assets/images/svg/arrow-up.svg';
import useScroll from '../../hooks/use-scroll';
import useTranslations from '../../hooks/use-translations';

import Button from '../atoms/button';

const UpwardsButton = () => {
    const t = useTranslations('UpwardsButton');
    const [isScrolled, setIsScrolled] = useState<boolean>(false);

    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    useScroll(() => setIsScrolled(window.scrollY > SCROLL_LIMIT));

    return (
        <Button
            onClick={handleClick}
            className={`${button} ${overwrite} ${isScrolled ? active : ''}`}
            showPointer={false}
        >
            <div className={insideWrapper}>
                <span>{t.up}</span>
                <ArrowUpIcon />
            </div>
        </Button>
    );
};

const SCROLL_LIMIT = 1000;

export default UpwardsButton;
