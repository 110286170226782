import React from 'react';
import { Link } from 'gatsby';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    sectionStyle,
    overwrite,
    wrapper,
    container,
    item,
    disabled,
} from './breadcrumbs.module.scss';

import { ISection } from '../../models/section.model';

import Section from '../hoc/section';

interface IBreadcrumbsProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ section }) => {
    const { sectionId, css, style } = section;
    const { breadcrumbs } = usePageContext();

    return (
        <Section
            sectionId={sectionId}
            className={`${sectionStyle} ${overwrite}`}
            css={css}
            style={style}
        >
            <div className={wrapper}>
                <div className={container}>
                    {breadcrumbs.map(({ label, pathname }, index) => {
                        if (index + 1 < breadcrumbs.length && pathname) {
                            return (
                                <Link key={`breadcrumb-${index}`} to={pathname} className={item}>
                                    {label}
                                </Link>
                            );
                        }

                        return (
                            <span
                                key={`breadcrumb-${index}`}
                                className={[item, disabled].join(' ')}
                            >
                                {label}
                            </span>
                        );
                    })}
                </div>
            </div>
        </Section>
    );
};

export default Breadcrumbs;
