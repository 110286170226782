import { useEffect, useRef, useState } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';
import axios, { AxiosError } from 'axios';

import { IPagination } from '../models/pagination.model';
import { TStatus } from '../models/status.model';
import { IFilter, IFilters, ISelectedFilter } from '../models/filter.model';
import { IApiKeywords } from '../models/api-keywords.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { getNodes } from '../utils/get-nodes';

const DEFAULT_PER_PAGE = 12;
const DEFAULT_INITIAL_PAGE = 1;

interface IUseListConfig {
    endpoint: string;
    additionalParams?: Record<string, string>;
    perPage?: number;
    sort?: string;
}

interface IAlterpressPaginatedResponse<Items> {
    items: Items[];
    pagination?: IPagination | null;
    filters?: IFilters | null;
    sort?: IFilter | null;
}

type TStaticQueryResult = {
    allApiKeywords: IQueryAllResult<IApiKeywords>;
};

const defaultKeywords = {
    locale: 'en',
    perPage: 'per-page',
    search: 'search',
    page: 'page',
    sort: 'sort,',
    categories: '',
};

export const useList = <Items>(config: IUseListConfig) => {
    const { endpoint, additionalParams, perPage = DEFAULT_PER_PAGE } = config;
    const { locale } = usePageContext();
    const { allApiKeywords } = useStaticQuery<TStaticQueryResult>(query);
    const apiKeywords = getNodes(allApiKeywords).find((keywords) => keywords.locale === locale);
    const search = typeof window !== 'undefined' ? window.location.search : '';
    const { current: keywords } = useRef<IApiKeywords>({
        locale: apiKeywords?.locale || defaultKeywords.locale,
        perPage: apiKeywords?.perPage || defaultKeywords.perPage,
        search: apiKeywords?.search || defaultKeywords.search,
        sort: apiKeywords?.sort || defaultKeywords.sort,
        page: apiKeywords?.page || defaultKeywords.page,
        categories: apiKeywords?.categories || defaultKeywords.categories,
    });

    const initialSearchValueRef = useRef(getInitialSearchValue(search, keywords.search));
    const paramsRef = useRef<Record<string, string | string[]> | null>(null);
    const prevSearchRef = useRef<string | undefined>();

    const [isInitialLoadingDone, setIsInitialLoadingDone] = useState(false);
    const [data, setData] = useState<IAlterpressPaginatedResponse<Items>>({ items: [] });
    const [paginationPaths, setPaginationPaths] = useState<string[]>([]);
    const [status, setStatus] = useState<TStatus>('loading');
    const [selectedFilters, setSelectedFilters] = useState<ISelectedFilter[]>([]);
    const [values, setValues] = useState<Record<string, string | string[]>>({});

    const handleSearch = (value: string) => {
        if (typeof window === 'undefined') return;

        const searchParams = new URLSearchParams(search);
        const searchValue = searchParams.get(keywords.search) || '';

        if (value === searchValue) return;

        searchParams.delete(keywords.page);

        if (value) {
            searchParams.set(keywords.search, value);
        } else {
            searchParams.delete(keywords.search);
        }

        const newSearchParamsString = searchParams.toString();
        const pathToNavigate = `${window.location.pathname}${
            newSearchParamsString ? `?${newSearchParamsString}` : ''
        }`;

        navigate(pathToNavigate);
    };

    const handleChange = (params: Record<string, string | string[]>) => {
        if (!isInitialLoadingDone) return;
        paramsRef.current = params;

        const newSelectedFilters = getSelectedFilters(data.filters, params);

        setSelectedFilters(newSelectedFilters);

        const prevSearchParams = new URLSearchParams(search);
        prevSearchParams.delete(keywords.page);
        const newSearchParams = new URLSearchParams();

        const paramsEntries = Object.entries(params);
        const paramsEntriesWithValues = paramsEntries.filter((entry) => {
            const value = entry[1];
            if (!Array.isArray(value)) return !!value;
            return value.length > 0;
        });

        for (const [paramName, paramValue] of paramsEntriesWithValues) {
            const filter =
                data.filters &&
                Object.values(data.filters).find((filter) => filter.paramName === paramName);
            let searchParamValue = paramValue;
            if (Array.isArray(searchParamValue)) {
                if (filter && filter.type === 'radio-range' && Array.isArray(searchParamValue)) {
                    searchParamValue = searchParamValue.map((value) => value.replaceAll(',', '.'));
                }
                searchParamValue = searchParamValue.join(',');
            }
            newSearchParams.set(paramName, searchParamValue);
        }

        const searchValue = prevSearchParams.get(keywords.search);
        if (searchValue) {
            newSearchParams.set(keywords.search, searchValue);
        }

        const newSearchParamsString = getSearchParamsString(newSearchParams);
        const prevSearchParamsString = getSearchParamsString(prevSearchParams);

        if (newSearchParamsString === prevSearchParamsString) return;

        const pathToNavigate = `${window.location.pathname}${newSearchParamsString}`;

        navigate(pathToNavigate, { state: { preventScroll: true } });
    };

    useEffect(() => {
        if (prevSearchRef.current === search) return;
        prevSearchRef.current = search;

        setStatus('loading');

        const abortController = new AbortController();
        const searchParamsObject = getSearchParamsObject(search, additionalParams);

        axios
            .get<IAlterpressPaginatedResponse<Items>>(`${process.env.API_URL}${endpoint}`, {
                params: {
                    [keywords.perPage]: perPage,
                    [keywords.page]: DEFAULT_INITIAL_PAGE,
                    ...searchParamsObject,
                },
                headers: { 'Accept-Language': locale },
                signal: abortController.signal,
            })
            .then((response) => {
                setStatus('success');
                const usableFilters = getUsableFilters(response.data.filters);
                const dataWithUsableFilters = { ...response.data, filters: usableFilters };
                setData(dataWithUsableFilters);
                setPaginationPaths(getPaginationPaths(response.data.pagination, keywords));
                const newValues = getValues(search, keywords, usableFilters, response.data.sort);
                setValues(newValues);
                const valuesForSelectedFilters = paramsRef.current || newValues;
                setSelectedFilters(getSelectedFilters(usableFilters, valuesForSelectedFilters));
                setIsInitialLoadingDone(true);
            })
            .catch((error: AxiosError) => {
                // ERR_CANCELED means that this request was aborted by the AbortController
                // and next response is on its way, so we don't want to show error
                if (error.code === 'ERR_CANCELED') return;
                setStatus('error');
            });

        return () => {
            abortController.abort();
        };
    }, [additionalParams, locale, perPage, search, endpoint, keywords, data.filters, data.sort]);

    return {
        items: data.items,
        filters: data.filters,
        sort: data.sort,
        pagination: data.pagination,
        status,
        isInitialLoading: !isInitialLoadingDone,
        paginationPaths,
        selectedFilters,
        handleSearch,
        handleChange,
        values,
        initialSearchValue: initialSearchValueRef.current,
    };
};

function getUsableFilters(
    filters: IAlterpressPaginatedResponse<unknown>['filters']
): IAlterpressPaginatedResponse<unknown>['filters'] {
    if (!filters) return filters;
    const usableFilters: IFilters = {};
    Object.entries(filters).forEach(([key, filter]) => {
        if (!filter.usable) return;
        usableFilters[key] = filter;
    });
    return usableFilters;
}

function getSearchParamsString(searchParams: URLSearchParams) {
    let searchParamsString = searchParams.toString().replace(/%2C/g, ',');
    if (searchParamsString) {
        searchParamsString = `?${searchParamsString}`;
    }
    return searchParamsString;
}

function getSelectedFilters(
    filters?: IFilters | null,
    formValues?: Record<string, string | string[]> | null
) {
    if (!filters || !formValues) return [];
    return Object.entries(formValues)
        .map(([key, value]) => {
            const filter = Object.values(filters).find((filter) => filter.paramName === key);
            if (!filter) return [];
            const valueArr = Array.isArray(value) ? value : [value];
            return valueArr
                .map((valueItem, index) => {
                    let option = filter.options.find((option) => option.value === valueItem);
                    let rangeEdge;
                    if (filter.type === 'radio-range' && !option) {
                        const optionLabel = `${['min', 'max'][index]} ${valueItem}`;
                        rangeEdge = ['min', 'max'][index];
                        option = {
                            value: valueItem,
                            label: optionLabel,
                            applied: true,
                        };
                    }
                    return {
                        paramName: key,
                        paramLabel: filter.label,
                        filterType: filter.type,
                        rangeEdge,
                        option,
                    };
                })
                .filter((selectedFilter) => !!selectedFilter.option && selectedFilter.option.value);
        })
        .flat() as ISelectedFilter[];
}

function getValues(
    search: string,
    apiKeywords: IApiKeywords,
    filters: IFilters | null | undefined,
    sortFilter: IFilter | null | undefined
) {
    const keysToOmit = [apiKeywords.search, apiKeywords.page];
    const searchParams = new URLSearchParams(search);
    const values: Record<string, string | string[]> = {};

    for (const [paramKey, paramValue] of searchParams) {
        if (keysToOmit.includes(paramKey)) continue;
        let filter: IFilter | undefined;
        if (sortFilter && paramKey === sortFilter.paramName) {
            filter = sortFilter;
        } else if (filters) {
            filter = Object.values(filters).find((filter) => filter.paramName === paramKey);
        }
        if (!filter) continue;
        if (filter.type === 'checkbox') {
            values[paramKey] = paramValue.split(',');
        }
        if (filter.type === 'radio') {
            values[paramKey] = paramValue;
        }
        if (filter.type === 'radio-range') {
            const valueArr = paramValue.split(',');
            values[paramKey] = valueArr.length > 1 ? valueArr : paramValue;
        }
    }

    return values;
}

function getInitialSearchValue(search: string, searchKey: string) {
    if (typeof window === 'undefined') return '';
    const searchParams = new URLSearchParams(search);
    return searchParams.get(searchKey) || '';
}

function getSearchParamsObject(
    search: string,
    additionalParams: IUseListConfig['additionalParams']
) {
    const searchParams = new URLSearchParams(search);
    const searchParamsObject: Record<string, string> = {};

    for (const [key, value] of searchParams) {
        searchParamsObject[key] = value;
    }

    if (additionalParams) {
        for (const [key, value] of Object.entries(additionalParams)) {
            if (searchParamsObject[key]) {
                searchParamsObject[key] = `${searchParamsObject[key]},${value}`;
            } else {
                searchParamsObject[key] = value;
            }
        }
    }

    return searchParamsObject;
}

function getPaginationPaths(pagination: IPagination | null | undefined, apiKeywords: IApiKeywords) {
    if (!pagination) return [];
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(apiKeywords.page);

    const paginationPaths = [];
    const search = searchParams.toString();
    const pathname = window.location.pathname;

    for (let i = 0; i < pagination.pageCount; i++) {
        const page = i + 1;
        const pageParams = page === 1 ? '' : `${apiKeywords.page}=${page}`;
        let params = `?${search}&${pageParams}`;
        if (!search) {
            params = pageParams ? `?${pageParams}` : '';
        }
        if (!pageParams) {
            params = search ? `?${search}` : '';
        }
        const url = `${pathname}${params}`;
        paginationPaths.push(url);
    }

    return paginationPaths;
}

const query = graphql`
    query {
        allApiKeywords {
            edges {
                node {
                    locale
                    search
                    sort
                    page
                    perPage
                }
            }
        }
    }
`;
